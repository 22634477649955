<template>
  <div :style="getStyles" :id="blockId" class="homeblock-container" :class="[blockId, {'home-block-full-width': fullWidth}]">
    <component
      v-for="(item, index) in toComponents"
      v-bind="{...item.props, observeIndividualItem: !observeSection}"
      :key="index"
      :is="item.type"
      :block-id="blockId"
    />
  </div>
</template>

<script>
import { transpileCmsContent } from '../transpilers';
import CmsComponents from '../mixins/CmsComponents';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  name: 'Homeblock',
  props: {
    blockId: {
      type: String,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: () => false
    },
    body: {
      type: Array,
      default: () => [{}]
    },
    editable: {
      type: Object,
      default: () => {}
    },
    observeSection: {
      type: Boolean,
      default: false
    }
  },
  mixins: [CmsComponents],
  data () {
    return {
      hasIntersected: false
    };
  },
  computed: {
    getStyles () {
      return {
        '--cms-homeblock-container-width': this.fullWidth ? '100%' : '1280px'
      };
    },
    toComponents () {
      return transpileCmsContent({ content: { body: this.body } });
    }
  },
  mounted () {
    if (!this.observeSection) return;
    this.observer = new IntersectionObserver(
      (entries) => {
        const blockEl = entries[0];
        if (blockEl.isIntersecting) {
          const [toComponent] = this.toComponents;
          const { props: toComponentProps } = toComponent || {};
          const { title: titleInProps } = toComponentProps || {};
          const types = {
            homepage_hero_carousel: toComponentProps?.bannersGroup?.[0].props?.title,
            homepage_product_carousel: titleInProps,
            grid_slider: titleInProps,
            grid: titleInProps || toComponentProps?.columns?.[0]?.subtitle,
            video_clp: titleInProps
          };
          const name = types[toComponent?.type] || null;
          this.$gtm?.trackEvent({ ecommerce: null });
          // view tracking of each story block id not individual promo items
          EventBus.$emit('home-block-promo-view', {
            id: blockEl.target.id,
            name,
            creative: null,
            position: null
          });
          this.observer.disconnect();
        }
      },
      {
        threshold: 0.7
      }
    );

    setTimeout(() => {
      this.observer.observe(this.$el);
    }, 500);
  },
  destroyed () {
    if (!this.observeSection) return;
    this.observer.disconnect();
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.homeblock-container {
  margin: 0 auto;
  max-width: var(--cms-homeblock-container-width);
  &.homepage_lb_benefits.home-block-full-width {
    ::v-deep .lb-benefits {
      justify-content: space-around;
      padding: 40px;
    }
  }
  &.homepage_about_brand {
    margin-top: 40px;
    @include for-mobile {
      margin-top: 20px;
    }
    ::v-deep {
      .homepage-about-brand {
      height: max-content;
      padding: 0 5px;
      @include for-mobile {
        padding: 0 20px;;
      }
    }
    .text-content {
      @include for-mobile {
        padding-bottom: 0;
      }
    }
    .image-content {
      @include for-desktop {
        min-height: 500px;
        max-height: 550px;
      }
    }
    }
  }
  &.homepage_ugc, &.homepage_ugc_carousel {
    margin-top: 10px;
    @include for-desktop {
      margin-top: 58px;
    }
  }
  &.homepage_campaign_block {
    ::v-deep {
      .cms-grid-slider {
        padding: 0;
        @include for-desktop {
          margin-top: 64px;
        }
      }
      .glide__slides.sf-carousel__slides {
        justify-content: space-between;
      }
      .sf-carousel .glide__track {
        @include for-mobile {
          margin-top: 24px;
        }
      }
      .card-image {
        @include for-mobile {
          padding: 0 20px;
        }
      }
      .slider-title {
        @include for-desktop {
          margin-bottom: 40px;
        }
        @include for-mobile {
          margin-left: 20px;
        }
      }
      .sf-carousel__controls {
        @include for-mobile {
          right: 20px;
        }
        @include for-desktop {
          height: 0;
        }
      }
    }
  }
  &.homepage_product_carousel {
    @include for-desktop {
      margin: 24px 0;
    }

    ::v-deep .m-product-carousel {
      .product-carousel__title {
        font-size: 40px !important;
        margin-bottom: 40px !important;
        @include for-mobile {
        font-size: 32px !important;
        margin-bottom: 24px !important;
        }
      }
      .product-carousel-banner {
        @include for-mobile {
          margin-top: 24px;
        }
      }
    }
  }
  ::v-deep  .glide__slide {
    width: auto;
    margin-right: 5px
  }
}

.homepage_lb_benefits {
  margin: 40px auto;
}
.homepage_highlights {
  @include for-mobile {
    padding: 0 20px;
  }
}
.homepage_ugc, .homepage_ugc_carousel {
  ::v-deep .ugc-carousel-container {
    @include for-mobile {
      padding: 0 20px;
      .title {
        position: absolute;
        text-align: left;
        padding: 0;
        margin-top: -10px;
      }
    }
    .ugc-carousel {
      @include for-desktop {
        margin-top: 25px;
      }
    }
  }
}
</style>
